import styled from "styled-components";
import React, {ReactNode, Suspense} from "react";
import {createPortal} from "react-dom";
import {GrClose} from "react-icons/gr";
import TimedDashWaiter from "../../atoms/TimedWaitUser/TimedDashWaiter";

interface TimedModalCanalCtrlProps{
    className?:string;
    open:boolean;
    closeMe:()=>void;
    children:ReactNode;
}
const TimedModalCanalCtrl = ({open, ...props}:TimedModalCanalCtrlProps)=>{
    if(!open){
        return null;
    }
    return createPortal(
        <div className={`timedModalCanal ${props.className}`}>
            <div className="back_black" onClick={()=>props.closeMe()}/>
            <div className="in_mod">
                <div className="canal_central" >
                    <div className="close_me" onClick={()=>props.closeMe()}><GrClose/></div>
                    <Suspense fallback={<TimedDashWaiter/>}>
                        {props.children}
                    </Suspense>
                </div>
            </div>
        </div>, document.body
    )
}

const TimedModalCanal = styled(TimedModalCanalCtrl)`
    position: fixed;
    z-index: 999;
    left:0;
    top:0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.75);
    overflow: auto;
    .back_black{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
    }
    .in_mod{
        z-index: 2;
        margin: 48px auto;
        width: 768px;
    }
    .canal_central{
        position: relative;
        box-sizing: border-box;
        width: 768px;
        overflow: visible;
        border-radius: 12px;
        background-color: white;
        box-shadow: var(--ds-shadow-overlay, 0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31));
        padding: 20px 2.5rem;
        .close_me{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 20px;
        }
    }
`

export default TimedModalCanal
