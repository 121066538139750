import styled from "styled-components";
import {ReactNode} from "react";

interface NewsHeaderCtrlProps{
    className?:string;
    titre:string;
    children:ReactNode;
}

const NewsHeaderCtrl = (props:NewsHeaderCtrlProps)=>{
    return (
        <div className={`newsHeader ${props.className}`}>
            <div className="lib_titre">
                {props.titre}
            </div>
            {props.children &&
                <div className="actions_part">{props.children}</div>
            }
        </div>
    )
}

const NewsHeader = styled(NewsHeaderCtrl)`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 25px;
    align-items: center;
    .lib_titre{
        flex-grow: 1;
        font-size: 24px;
        color: ${props => props.theme.Primary};
        font-weight: bold;
    }
    .actions_part{
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
`

export default NewsHeader
