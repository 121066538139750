import React, {createContext, lazy, Suspense, useState} from 'react';
import './App.css';
import {ThemeProvider} from "styled-components";
import TimedTheme from "../assets/styles/TimedTheme";
import GlobalStyle from '../assets/styles/Global';
import {QueryClient, QueryClientProvider} from "react-query";
import {Routes, Route, Navigate} from "react-router-dom";
import PrivateRoute from "../components/security/PrivateRoute";
import PublicRoute from "../components/security/PublicRoute";
import NotFound from '../pages/NotFound/NotFound';
import { ReactQueryDevtools } from "react-query/devtools";
import MainTest from "../pages/Tests/MainTest";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Confidential from "../pages/Confidential/Confidential";
import WebmasterRoute from "../components/security/WebmasterRoute";
import {WebMasterApplication} from "../pages/WebMasterApplication/WebMasterApplication";
import {ViewportProvider} from "../services/providers/ViewportProvider";
import {LoginPre} from "../pages/Login/LoginPre";
import SupervisorRoute from "../components/security/SupervisorRoute";
import SupervisorController from "../pages/SupervisorApplication/SupervisorController";
import PwdRetrieve from "../pages/TimedApplication/PwdRetrieve/PwdRetrieve";
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import TimedSuspense from "../components/atoms/TimedWaitUser/TimedSuspense";
import "../pages/TimedApplication/SettingNew/styles/StyleParams.css"

const ApplicationController = lazy(()=>import("../pages/TimedApplication/ApplicationController"));

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense:true,
            staleTime: 1000 * 60 * 5, // 5 minutes
            cacheTime: 1000 * 60 * 10, // 10 minutes
            retry: 2, // Réessayer jusqu'à 2 fois en cas d'échec
            refetchOnWindowFocus: false, // Pas de refetch automatique lors du focus
        },
    },
});
interface TimedCont{
    isFullScreen:boolean,
    SwitchFS:()=>void;
    SetSideStyle:(nb:number)=>void;
    myStyleSide:number;
}
export const TimedContext = createContext<TimedCont>({isFullScreen:false, SwitchFS:()=>{}, SetSideStyle:(nb:number)=>{
        console.log(nb)}, myStyleSide:1})
function App() {
    const [fullScreen , setFullScreen] = useState(false)
    const [myStyleSide, setMyStyleSide] = useState(1)
    const SwitchFS = ()=>{
        setFullScreen(o=>!o)
    }
    const SwitchSideStyle = (nb:number)=>{
        setMyStyleSide(nb);
    }
  return (
      <TimedContext.Provider value={{SwitchFS:SwitchFS, isFullScreen:fullScreen, SetSideStyle:SwitchSideStyle, myStyleSide:myStyleSide}}>
            <ThemeProvider theme={TimedTheme}>
              <GlobalStyle />
                <QueryClientProvider client={queryClient}>
                    <ViewportProvider>
                      <div className="App" style={{height:"100%", userSelect:"none"}}>
                          <Routes>
                              <Route element={<PrivateRoute from={"App"}/>}>
                                    <Route path="/timed_application/*" element={
                                        <Suspense fallback={<TimedSuspense/>}>
                                            <ApplicationController />
                                        </Suspense>
                                    } />
                              </Route>
                              <Route element={<WebmasterRoute from={"App"}/>}>
                                  <Route path="/webmaster_application/*" element={<WebMasterApplication />} />
                              </Route>
                              <Route element={<SupervisorRoute from={"App"}/>}>
                                  <Route path={"/supervisor_application/*"} element={<SupervisorController/>} />
                              </Route>
                              <Route element={<PublicRoute/>}>
                                  <Route path="/" element={<Navigate to="/login" />} />
                                  <Route path="/login" element={<LoginPre />} />
                                  <Route path="/confidential" element={<Confidential/>} />
                                  <Route path="/pwd_retrieve" element={<PwdRetrieve />} />
                              </Route>
                              <Route path="/tests" element={<MainTest />}/>
                              <Route path="*" element={<NotFound />} />
                          </Routes>
                          <ToastContainer
                              position="bottom-right"
                              autoClose={5000}
                              hideProgressBar={false}
                              newestOnTop={false}
                              closeOnClick
                              rtl={false}
                              pauseOnFocusLoss
                              draggable
                              pauseOnHover
                          />
                      </div>
                    </ViewportProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </ThemeProvider>
      </TimedContext.Provider>
  );
}

export default App;
