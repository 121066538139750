import styled from "styled-components";
import React, {useEffect, useState} from "react";
import InputText from "../../../../../components/atoms/TimedForm25/InputText";
import InputTextArea from "../../../../../components/atoms/TimedForm25/InputTextArea";
import TimedSwitch from "../../../../../components/atoms/TimedForm324/TimedSwitch";
import TimedButton from "../../../../../components/atoms/TimedButton/TimedButton";
import {TimedDropZoneImage} from "../../../../../components/molecules/TimedDropZoneImage/TimedDropZoneImage";
import {useAddNews, useModifyNews, usePutNullEndAtNews} from "../../../../../features/news/news.hooks";
import {useGetUser} from "../../../../../features/user/user.hooks";
import {toast} from "react-toastify";

interface FormNouvelleEditNewsCtrlProps{
    className?:string;
    News?:News|null;
    closeMe:()=>void;
}

interface MyNewsProps{
    titre:string,
    sujet:string,
    content:string,
    startAt:string,
    endAt:string,
    isActive:boolean,
    isBrouillon:boolean,
    image:string;
}
const MyNewsDefault:MyNewsProps={
    titre:"",
    sujet:"",
    content:"",
    startAt:"",
    endAt:"",
    isActive:true,
    isBrouillon:false,
    image:""
}

interface ErrorsProps{
    titre:string|null,
    sujet:string|null,
    content:string|null,
    startAt:string|null,
    endAt:string|null,
}
const ErrorsDefault:ErrorsProps={
    titre:null,
    sujet:null,
    content:null,
    startAt:null,
    endAt:null,
}

const FormNouvelleEditNewsCtrl = (props:FormNouvelleEditNewsCtrlProps)=>{
    const mutation = useAddNews(null);
    const mutationMod = useModifyNews();
    const mutationNullEndAt=usePutNullEndAtNews();
    const UserQuery = useGetUser();
    const {closeMe, News} = props;
    const [image, setImage] = useState<File|null>(null);
    const [myNews, setMyNews] = useState<MyNewsProps>(!News ? MyNewsDefault : {titre:News.titre, sujet:News.sujet, content:News.content, startAt:News.startAt.slice(0,10), endAt:!News.endAt ? "" : News.endAt.slice(0,10), isActive:News.isActive, isBrouillon:false, image:News.illustrationUrl||""})
    const [Errors, setErrors] = useState<ErrorsProps>(ErrorsDefault)
    const [ErrorSave, setErrorSave] = useState<string>("")
    useEffect(() => {
        if(News) {
            setMyNews({
                titre: News.titre,
                sujet: News.sujet,
                content: News.content,
                startAt: News.startAt.slice(0,10),
                endAt: !News.endAt ? "" : News.endAt.slice(0,10),
                isActive: News.isActive,
                isBrouillon: false
                , image:News.illustrationUrl||""
            })
        } else {
            setMyNews(MyNewsDefault)
        }
    }, [News]);
    const SwitchActive = ()=>{
        setMyNews(prevState => {
            return {...prevState, isActive:!myNews.isActive}
        })
    }
    const HandleValue = (v:string, id:string)=>{
        if(id==="endAt"){
            if(myNews.startAt!=="" && new Date(myNews.startAt) > new Date(v)){
                setErrors(prevState => {
                    return {...prevState, endAt:"La date doit être postérieure à la date de départ"}
                })
            } else {
                setErrors(prevState => {
                    return {...prevState, endAt:null}
                })
            }
        }
        setMyNews(prevState => {
            return {...prevState, [id]:v}
        })
    }
    const SwitchErrors = (id:string, v:string|null)=>{
        setErrors(prevState => {
            return {...prevState, [id]:v}
        })
    }
    const Submit = ()=>{
        let nbErrors = 0;
        if(myNews.titre === ""){
            SwitchErrors("titre", "Titre obligatoire")
            nbErrors++;
        } else {
            SwitchErrors("titre", null)
        }
        if(myNews.sujet === ""){
            SwitchErrors("sujet", "Sujet obligatoire")
            nbErrors++;
        } else {
            SwitchErrors("sujet", null)
        }
        if(myNews.content === ""){
            SwitchErrors("content", "Contenu obligatoire")
            nbErrors++;
        } else {
            SwitchErrors("content", null)
        }
        if(myNews.startAt === ""){
            SwitchErrors("startAt", "Date obligatoire")
            nbErrors++;
        } else {
            SwitchErrors("startAt", null)
        }
        if(nbErrors === 0 && UserQuery.data){
            const data:NewsFD = {
                titre:myNews.titre,
                sujet:myNews.sujet,
                content:myNews.content,
                startAt:myNews.startAt,
                Centre:`/api/centres/${UserQuery.data.Centre.id}`,
                UserCreate:`/api/users/${UserQuery.data.id}`,
                isActive:myNews.isActive ? "1" : "0",
                isBrouillon:"0"
            }
            if(!!image){
                data.file = image;
            }
            if(!News){
                SaveNewNews(data)
            } else {
                data.id = News.id;
                SaveModifyNews(data);
            }
        }

    }
    const SaveNewNews = (data:NewsFD)=>{
        if(myNews.endAt !==""){
            data.endAt = myNews.endAt;
        }
        mutation.mutate(data, {
            onSuccess:()=>{
                toast.success("News créée avec succès");
                closeMe();
            },onError:()=>{
                setErrorSave("Impossible d'enregistrer la news, merci de contacter le support");
            }
        })
    }
    const SaveModifyNews = (data:NewsFD)=>{
        console.log(myNews.endAt==="");
        if(myNews.endAt==="" ){
            mutationNullEndAt.mutate((data.id as number), {
                onSuccess:()=>{
                    mutationMod.mutate(data, {
                        onSuccess:()=>{
                            toast.success("News modifiées avec succès");
                            closeMe();
                        },onError:()=>{
                            setErrorSave("Impossible d'enregistrer la news, merci de contacter le support");
                        }
                    })
                }
            })
        } else {
            if(myNews.endAt!==""){
                data.endAt = myNews.endAt;
            }
            mutationMod.mutate(data, {
                onSuccess:()=>{
                    toast.success("News modifiées avec succès");
                    closeMe();
                },onError:()=>{
                    setErrorSave("Impossible d'enregistrer la news, merci de contacter le support");
                }
            })
        }
    }
    return (
        <div className={`FormNouvelleEditNews ${props.className}`}>
            <div className={`wrap-drop-image`}>
                <TimedDropZoneImage setImage={setImage} width={"100%"} height={"100%"} {...(myNews.image!=="" && {
                    imageBase: `${process.env.REACT_APP_API_URL_ROOT}${myNews.image}`
                })}/>
            </div>
            <div className="wrap_input">
                <InputText Error={Errors.titre} label={"Titre*"} isPending={false} type={"text"} current={myNews.titre}
                           setValue={(v: string) => HandleValue(v, "titre")} id={"titre"} isFormStd={true}
                           isAlws={true}/>
                <InputText Error={Errors.sujet} label={"Sujet*"} isPending={false} type={"text"} current={myNews.sujet}
                           setValue={(v: string) => HandleValue(v, "sujet")} id={"sujet"} isFormStd={true}
                           isAlws={true}/>
                <InputTextArea Error={Errors.content} label={"Contenu"} isPending={false} current={myNews.content}
                               setValue={(v: string) => HandleValue(v, "content")} id={"content"} isFormStd={true}
                               isAlws={true}/>
                <InputText Error={Errors.startAt} label={"Début de la publication*"} isPending={false} type={"date"}
                           current={myNews.startAt} setValue={(v: string) => HandleValue(v, "startAt")} id={"startAt"}
                           isFormStd={true} isAlws={true}/>
                <InputText Error={Errors.endAt} label={"Fin de la publication (facultatif)"} isPending={false}
                           type={"date"} current={myNews.endAt} setValue={(v: string) => HandleValue(v, "endAt")}
                           id={"endAt"} isFormStd={true} isAlws={true}/>
                <TimedSwitch current={myNews.isActive} switchPos={SwitchActive} isInversed={true} label={"Active"}/>
                {ErrorSave !== "" &&
                    <div className="error_save">{ErrorSave}</div>
                }

            </div>
            <div className="wrap_send">
                <TimedButton onClick={Submit} text={"Enregistrer"} size={"sm"} themeColor={"Primary"} isPending={mutation.isLoading || mutationMod.isLoading || mutationNullEndAt.isLoading} disabled={mutation.isLoading || mutationMod.isLoading || mutationNullEndAt.isLoading}/>
            </div>
        </div>
    )
}

const FormNouvelleEditNews = styled(FormNouvelleEditNewsCtrl)`
    display: flex;
    flex-direction: column;
    gap: 30px;
    .wrap-drop-image{
        width: 180px;
        height: 180px;
        margin: 10px auto;
    }
    .wrap_input {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .wrap_send{
        display: flex;
        justify-content: flex-end;
    }
`

export default FormNouvelleEditNews
