import styled from "styled-components";
import {useGetIncident} from "../../../features/incident/incident.hooks";
import {useEffect, useState} from "react";
import {useModal} from "../../../hooks/useModal";
import TimedModalCanal from "../TimedModal/TimedModalCanal";
import ModalEtatService from "./ModalEtatService";
import {queryClient} from "../../../main/App";

interface EtatServiceCtrlProps{
    className?:string;
}

const EtatServiceCtrl = (props:EtatServiceCtrlProps)=>{
    const IncidentQuery = useGetIncident();
    const [niveau, setNiveau] = useState<number>(0)
    const [mesIncidents, setMesIncidents] = useState<Incident[]>([])
    const {open, toggle} = useModal()
    useEffect(() => {
        const myInterval = window.setInterval(()=>{
            queryClient.resetQueries(["incidents"])
        }, 30000)
        return ()=>{
            window.clearInterval(myInterval);
        }
    }, []);
    useEffect(() => {
        if(IncidentQuery.data){
            setMesIncidents(IncidentQuery.data.sort((a,b)=>new Date(a.incidentAt) > new Date(b.incidentAt) ? -1 : 1))
            let niveau = 0;
            IncidentQuery.data.forEach(i=>{
                if(i.niveau>niveau){
                    niveau = i.niveau
                }
            })
            setNiveau(niveau);
        }
    }, [IncidentQuery.data]);
    return (
        <div className={`etatService ${props.className} niveau-${niveau}`} >
            <div className="in_etat" onClick={toggle}/>
            <TimedModalCanal closeMe={toggle} open={open}>
                <ModalEtatService Incidents={mesIncidents}/>
            </TimedModalCanal>
        </div>
    )
}

const EtatService = styled(EtatServiceCtrl)`
    .in_etat{
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }
    &.niveau-0{
        .in_etat{
            background: ${props => props.theme.Secondary};
        }
    }
    &.niveau-1{
        .in_etat{
            background: ${props => props.theme.Warning};
        }
    }
    &.niveau-2{
        .in_etat{
            background: ${props => props.theme.ComplementaryDark};
        }
    }
`

export default EtatService
