import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    getAllNews,
    getNewsByParams,
    PostNewNews,
    PutNewNews,
    PutNullEndAtNews,
    PutOrdreNews,
    TrashNews
} from "./news.services";
import {useUserConnected} from "../../services/hooks";
import {toast} from "react-toastify";



export const useGetNewsByParams = (params:any|null|undefined, CentreId:number|null=null):UseQueryResult<News[], Error>=>{
    const userConnected:{ userConnected: UserSimple | undefined } = useUserConnected();
    if(!CentreId) {
        CentreId = (userConnected === undefined || userConnected.userConnected === undefined) ? null : userConnected.userConnected.Centre.id;
    }
    const myParams:any = params === undefined || params === null ? {Centre:CentreId} : {...params, Centre:CentreId}
    return useQuery<News[], Error>(["news", params, CentreId], ()=>getNewsByParams(myParams), {
        staleTime:5000,
        enabled:params!==undefined && CentreId!==undefined
    })
}

export const useGetAllNews = (idCentre:number):UseQueryResult<News[], Error>=>{
    return useQuery(["news_centre", idCentre], ()=>getAllNews(idCentre), {
        enabled:idCentre!==0
    })
}

export const useAddNews = (idCentre:number|null=null)=>{
    const queryClient = useQueryClient();
    const userConnected:{ userConnected: UserSimple | undefined } = useUserConnected();
    const UserStr =userConnected && userConnected.userConnected ?   `api/users/${userConnected.userConnected.id}` : '';
    let CentreStr = userConnected && userConnected.userConnected ? `api/centres/${userConnected.userConnected.Centre.id}` : '';
    if(idCentre) {
       CentreStr =  `api/centres/${idCentre}`
    }
    return useMutation(
        (data:NewsFD) => {
            data.Centre = CentreStr;
            data.UserCreate = UserStr;
            return PostNewNews(data);
        },
        {
            onSuccess: (newData) => {
                queryClient.invalidateQueries(["news"]);
                queryClient.invalidateQueries(["news_centre"]);
            }
        }
    );
}

export const useModifyNews = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:NewsFD) => {
            return PutNewNews(data);
        },
        {
            onSuccess: (newData) => {
                queryClient.invalidateQueries(["news"]);
                queryClient.invalidateQueries(["news_centre"]);
            }
        }
    );
}

export const useModifyOrdreNews = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data: { id:number, ordreAffichage:number }) => {
            return PutOrdreNews(data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["news"]);
                queryClient.invalidateQueries(["news_centre"]);
            }
        }
    );
}

export const usePutNullEndAtNews = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number) => {
            return PutNullEndAtNews(id);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["news"]);
                queryClient.invalidateQueries(["news_centre"]);
            }
        }
    );
}

export const useTrashNews = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number) => {
            return TrashNews(id);
        },
        {
            onSuccess: (newData) => {
                queryClient.invalidateQueries(["news"]);
                queryClient.invalidateQueries(["news_centre"]);
                toast.success("News supprimée !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    );
}
