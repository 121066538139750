import styled, {css, keyframes} from "styled-components";

export const myBoxShadow = css`
    box-shadow: 1px 1px 3px rgba(0,0,0,.2);
`

export const myBoxShadowG = css`
  box-shadow: 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2);
`

export const InactiveShadowSmartNav = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.75);
  z-index: 9;
`

export const ContentDash = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const WrapBodyDash = styled.div`
  width: 100%;
  //height: 100%;
  //overflow-y: auto;
  padding: 0.5rem 0;
`

export const BodyDash = styled.div`
  //height: calc(100% - 40px);
  flex-grow: 1;
  min-height: 0;
  overflow-y: auto;
  padding: 0 1.75rem;
  position: relative;
  &.suby{
    height: calc(100% - 55px);
    &.is-fixed{
      min-height: calc(100% - 55px);
      max-height: calc(100% - 55px);
    }
  }
`

export const TitleDash = styled.div`
  font-size: 14px;
  padding: 0.75rem .75rem 0.5rem 1rem;
  color:${props=>props.theme.Dark};
  .main-title{
    font-weight: bold;
  }
  .sub-title{
    font-size: 13px;
  }
  .wrap-titre-redirect{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .wrap-redirect{
      svg{
        color:${props=>props.theme.Dark};
      }
      &:hover{
        cursor: pointer;
      }
    }
  }
`

export const Widy = styled.div`
  background: white;
  &.is-alerte{
    border: solid 2px ${props => props.theme.ComplementaryLight};
  }
  &.is-no-alert{
    border: solid 2px ${props => props.theme.SecondaryLight};
  }
  //box-shadow: 1px 1px 3px rgba(0,0,0,.1);
  border-radius: 10px;
  //padding: 1rem 1.5rem;
  //border:${props=>props.theme.PrimaryExtraLight} solid 1px;
`

export const TitrePage = styled.h1`
  color:${props=>props.theme.Primary};
  font-size: clamp(16px, 2vw, 28px);
  padding: 0 0 0.5rem 0;
  margin-bottom: 10px;
    &.noMargin{
        padding: 0;
        margin: 0;
    }
`
export const TitrePageNoMarg = styled.h1`
  color:${props=>props.theme.Primary};
  font-size: 28px;
  padding: 0 0 0.5rem 0;
`

export const SubTitrePAge = styled.h2`
  color:${props=>props.theme.PrimaryDark};
  font-size: 18px;
  padding: 0 0 0.5rem 0;
  margin-bottom: 10px;
`

export const BtnClose = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
  &:hover{
    cursor: pointer;
    background: ${props=>props.theme.PrimaryExtraLight};
  }
  svg{
    font-size: 20px;
    margin: auto;
  }
`

export const WaitingAbs = keyframes`
    from{
      transform: translate(-50%,-50%) rotate(0deg);  
    }
  to{
    transform: translate(-50%,-50%) rotate(360deg);
  }
`


export const Ring = keyframes`
  0% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(-30deg);
  }
`

export const Wave = keyframes`
    0%{
      background: #E4E6E6;
    }
    50%{
      background: #B0BEBE;
    }
  0%{
    background: #E4E6E6;
  }
`

export const Shine = keyframes`
  to {
    background-position-x: -200%;
  }
`

export const OnQuickHor = styled.div`
  padding: 0.25rem 0.5rem;
  background: ${props=>props.theme.Tertiary};
  color:white;
  border-radius: 12px;
  font-size: 12px;
  &:hover{
    cursor: pointer;
    filter: brightness(90%);
  }
`

export const OnQuickHorStyleTwo = styled.div`
  padding: 0.5rem;
  background: ${props=>props.theme.PrimaryLight};
  color:white;
  border-radius: 6px;
  font-size: 12px;
  &.disabled{
    background: ${props=>props.theme.PrimaryExtraLight};
  }
  &:hover{
    cursor: pointer;
    filter: brightness(90%);
  }
`

export const WrapPlanning = styled.div`
  border-radius: 8px;
  margin: 10px auto;
  background: white;
  //min-width: 1300px;
  .wrapLines{
    width: 100%;
    //height: 550px;
    max-height: calc(100vh - 273px);
    overflow: hidden;
    .in-wrap-lines{
      width: calc(100% + 3px);
      max-height: calc(100vh - 273px);
      overflow-y: scroll;
    }
  }
`

export const AnimationRotateTimed = keyframes`
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
`

export const RotateCenter = keyframes`
    from{
      transform: translate(-50%,-50%) rotate(0deg);
    }
    to{
      transform: translate(-50%,-50%) rotate(360deg);
    }
`

export const TransH=styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0.5rem;
`
export const WrapHoraires=styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem 0 0.5rem;
  margin: 20px auto;
`
export const LineHours = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 0.5rem;
  .MuiFormControl-root {
    margin: 0;
  }
`

export const WrapSender = styled.div`
  display: flex;
  justify-content: end;
  padding: 0.5rem;
  align-items: center;
  gap: 10px;
`

export const HorseAlert = styled.div`
  margin: 20px auto;
  p{
    text-align: center;
    padding: 0.5rem;
    background: ${props=>props.theme.WarningDark};
    color:white;
  }
  ul{
    padding-left: 30px;
    margin-top: 10px;
    li{
      color: ${props=>props.theme.WarningDark};
      margin-bottom: 5px;
    }
  }
`

export const ZoneSearchMess = styled.div`
  padding: 0.5rem;
  position: relative;
  input{
    width: 100%;
    height: 38px;
    border:${props=>props.theme.PrimaryExtraLight} solid 1px;
    border-radius: 8px;
    padding-left: 0.5rem;
  }
  .clear-search{
    position: absolute;
    right: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    top:50%;
    transform: translateY(-50%);
    font-size: 22px;
    svg{
      color: red;
    }
    &:hover{
      cursor: pointer;
    }
  }
`
export const Deploy = styled.div`
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: rotate(0deg);
  transition: transform 0.3s;
  &:hover{
    cursor: pointer;
  }
  &.isOpen{
    transform: rotate(180deg);
    transition: transform 0.3s;
  }
`

export const LineTopPilotagePl = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: stretch;
  .user-place{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .date-place, .have-st{
    padding: 0 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .content-date-place{
      font-size: 13px;
      text-align: center;
    }
    .lib{
      font-weight: bold;
      margin-right: 8px;
    }
    .hav-st-content{
      font-weight: bold;
      &.success{
        color:${props=>props.theme.Success};
      }
      &.warning{
        color:${props=>props.theme.Warning};  
      }
    }
  }
  .Metrix-place{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  .wrap-suppr{
    display: flex;
    flex-grow: 1;
    justify-content: end;
    .suppr-place{
      height: 100%;
    }
  }
  .suppr-place{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
  }
`

export const LibTitleSmartNav = styled.div`
  border-bottom: solid ${props => props.theme.NeutreLight} 1px;
  margin-bottom: 10px;
  font-weight: 600;
  margin-top: 15px;
  &.disabled{
    color:${props=>props.theme.NeutreLight}
  }
`

export const WrapperMenus = styled.div`
    background: white;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    .one_choice_menu{
        padding: 0.25rem 0.5rem;
        color:${props=>props.theme.Dark};
        &.current{
            font-weight: bold;
            border-bottom: solid 2px ${props => props.theme.Dark};
        }
        &:hover{
            font-weight: bold;
            cursor: pointer;
        }
    }
`
