import styled from "styled-components";
import {useGetAllCentre} from "../../features/centre/centre.hooks";
import {
    useCancelWMSub, useGetAllAdmins,
    useGetAllUserD,
    useGetAllUserFull,
    useGetUserByCentre,
    useSaveWMSub
} from "../../features/user/user.hooks";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {TimedAutoCompletionMulti} from "../../components/atoms/TimedAutoCompletion/TimedAutoCompletionMulti";
import {DNA} from "react-loader-spinner";
import {useDebounce} from "../../services/hooks";
import {GrClose} from "react-icons/gr";

interface FormShadowConnectCtrlProps{
    className?:string;
}

const FormShadowConnectCtrl = (props:FormShadowConnectCtrlProps)=>{
    const CentresQuery = useGetAllCentre();
    const [CentreCurrent, setCentreCurrent] = useState<Centre|null>(null)
    const UsersQuery = useGetAllAdmins();
    const [UserShadowCurrent, setUserShadowCurrent] = useState<AdminData|null>(null);
    const mutation = useSaveWMSub();
    const mutationCancel = useCancelWMSub();
    const [Error, setError] = useState('');
    const [listAdmin, setListAdmin] = useState<AdminData[]>([])
    const [search, setSearch] = useState<string>("")
    const searchDebounce = useDebounce(search, 650)
    const refSearch = useRef<HTMLInputElement>(null)
    const [,setSearchParams] = useSearchParams();
    const handleChangeSearch = ()=>{
        setCentreCurrent(null);
        setUserShadowCurrent(null)
        if(refSearch.current){
            setSearch(refSearch.current.value)
        }
    }
    useEffect(() => {
        if(!!UsersQuery.data){
            setListAdmin(UsersQuery.data);
        }
    }, [UsersQuery.data])

    const handleClickConnexion = ()=>{
        setError('');
        if(UserShadowCurrent) {
            const datas: WMsubFormData = {
                SubstituateBy: `/api/users/${UserShadowCurrent.idUser}`
            }
            mutation.mutate((datas), {
                onSuccess: () => {
                    setSearchParams({});
                    window.location.reload();
                }, onError: () => {
                    setError("Une erreur est survenue");
                }
            })
        }
    }
    const CancelSub = ()=>{
        let x:void;
        mutationCancel.mutate(x, {
            onSuccess: () => {
                setSearchParams({});
                window.location.reload();
            }, onError: () => {
                setError("Une erreur est survenue");
            }
        })
    }
    const listAdminFiltered = useMemo(()=>{
        let myList:AdminData[] = [];
        if(listAdmin.length>0 && searchDebounce!==""){
            if(CentreCurrent){
                myList = listAdmin.filter(a=>a.id === CentreCurrent.id)
            } else {
                const regex = new RegExp(""+search+"", 'gi');
                myList = listAdmin.filter(x=>(`${x.prenom} ${x.nom}`).match(regex))
            }
        }
        return myList;
    }, [searchDebounce, listAdmin, CentreCurrent])
    const listCentreFiltered = useMemo(()=>{
        let myList:Centre[] = [];
        if(CentresQuery.data && searchDebounce!==""){
            const regex = new RegExp(""+search+"", 'gi');
            myList = CentresQuery.data.filter(c=>c.name.match(regex));
        }
        return myList;
    }, [CentresQuery.data, searchDebounce])
    return (
        <div className={`form_shadow_connect ${props.className}`}>
            <div className={`wrap_cancel`}>
                <button type={"button"} className={`btn_cancel`} disabled={mutationCancel.isLoading} onClick={CancelSub}>
                    {mutationCancel.isLoading ?
                        <div className={`loading_btn`}>
                            <DNA
                                visible={true}
                                height="30"
                                width="30"
                                ariaLabel="dna-loading"
                                wrapperStyle={{}}
                                wrapperClass="dna-wrapper"
                            />
                            Connexion en cours...
                        </div>:
                        <div>Je redeviens moi même !</div>
                    }
                </button>
            </div>
            {CentresQuery.isLoading  ? 'chargement...' :
                <>
                    <div className="wrap_search">
                        <input className={`search_place`} placeholder={"Recherche admin ou centre"} ref={refSearch} value={search} onChange={handleChangeSearch}/>
                        {search!=="" &&
                            <button className={`btn_close_search`} onClick={()=>setSearch("")}>
                                <GrClose/>
                            </button>
                        }
                    </div>
                    <div className="wrap_result">
                        <div className="wrap_result_wrapper">
                            <div className="title_result">Centres</div>
                            {listCentreFiltered.length === 0 && searchDebounce!=="" ?
                                <div className="not_result">Aucun centre trouvé</div>:
                                searchDebounce === "" ?
                                    <div className="not_result">En attente d'une recherche</div>:
                                <div className="wrap_result_list">
                                    {listCentreFiltered.map(item=>(
                                        <div key={`C${item.id}`} className={`one_choix_centre ${CentreCurrent?.id === item.id ? "selected" : ""}`} onClick={()=>setCentreCurrent(item)}>{item.name}</div>
                                    ))}
                                </div>
                            }
                        </div>
                        <div className="wrap_result_wrapper">
                            <div className="title_result">Admins</div>
                            {listAdminFiltered.length === 0 && searchDebounce!=="" ?
                                <div className="not_result">Aucun admin trouvé</div> :
                                searchDebounce === "" ?
                                <div className="not_result">En attente d'une recherche</div>:
                                <div className="wrap_result_list">
                                    {listAdminFiltered.map(item => (
                                        <div
                                            className={`one_choix_admin ${UserShadowCurrent?.id === item.id ? "selected" : ""}`}
                                            key={`A${item.idUser}`} onClick={() => setUserShadowCurrent(item)}>
                                            {`${item.prenom} ${item.nom.toUpperCase()}`} {!CentreCurrent ? `(${item.name})` : ''}
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                    {UserShadowCurrent &&
                        <div className={`choix_user_def`}>
                            <button type={"button"} className={`btn_choix`} disabled={mutation.isLoading} onClick={handleClickConnexion}>
                                {mutation.isLoading ?
                                    <div className={`loading_btn`}>
                                        <DNA
                                            visible={true}
                                            height="30"
                                            width="30"
                                            ariaLabel="dna-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="dna-wrapper"
                                        />
                                        Connexion en cours...
                                    </div>:
                                    <div>Se connected en temps que <br/>{UserShadowCurrent.prenom} {UserShadowCurrent.nom}</div>
                                }
                            </button>
                        </div>
                    }
                    {Error!=='' &&
                        <div className={"Error"}>{Error}</div>
                    }
                </>
            }
        </div>
    )
}

export const FormShadowConnect = styled(FormShadowConnectCtrl)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    .one_choix_centre, .one_choix_admin{
        padding: 0.25rem;
        &:hover, &.selected{
            cursor: pointer;
            font-weight: bold;
        }
    }
    .wrap_result_list{
        flex-grow: 1;
        padding: 0.5rem;
        overflow-y: auto;
    }
    .wrap_result{
        display: flex;
        justify-content: space-between;
        .wrap_result_wrapper{
            width: 48%;
            flex-shrink: 0;
            height: 25vh;
            flex-direction: column;
            display: flex;
            .not_result{
                padding: 1rem;
                text-align: center;
                font-style: italic;
            }
            .title_result{
                padding: 0.5rem;
                background: ${props => props.theme.NeutreMegaLight};
                border-radius: 4px 4px 0 0;
            }
            
        }
    }
    .wrap_search{
        width: 100%;
        height: 38px;
        position: relative;
        input{
            background: ${props => props.theme.colorBackInput};
            border: none;
            border-radius: 4px;
            height: 38px;
            padding-left: 5px;
            width: 100%;
        }
        button{
            position: absolute;
            flex-direction: column;
            justify-content: center;
            border: none;
            background: red;
            color: white;
            width: 30px;
            height: 30px;
            display: flex;
            right: 5px;
            top: 50%;
            border-radius: 4px;
            transform: translateY(-50%);
            svg{
                margin: auto;
            }
        }
    }
  .wrap_cancel{
    width: 100%;
  }
  .noCentre{
    text-align: center;
    font-weight: bold;
  }
   .user_wrap_choices{
     margin: 15px auto;
     .one_choice_user{
       padding: 0.25rem 0.5rem;
       border-radius: 6px;
       font-size: 14px;
       &:hover{
         cursor: pointer;
         font-weight: bold;
         background: ${props => props.theme.PrimaryMegaLight};
       }
     }
   } 
  .btn_choix{
    width: 100%;
    border-radius: 6px;
    padding: 0.25rem;
    display: block;
    border: solid 1px ${props=>props.theme.Secondary};
    background: ${props=>props.theme.SecondaryExtraLight};
    color: ${props=>props.theme.SecondaryExtraDark};
    &:hover{
      cursor: pointer;
      filter: brightness(95%);  
    }
    &:disabled{
      
    }
  }
  .btn_cancel{
    width: 100%;
    font-weight: bold;
    border-radius: 6px;
    padding: 0.5rem 0.25rem;
    display: block;
    border: none;
    background: ${props=>props.theme.ComplementaryExtraLight};
    color: ${props=>props.theme.ComplementaryExtraDark};
    &:hover{
      cursor: pointer;
      filter: brightness(95%);
    }
    &:disabled{

    }
  }
  .loading_btn{
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
`
