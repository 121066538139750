import styled from "styled-components";
import {format} from "date-fns";

interface ModalEtatServiceCtrlProps{
    className?:string;
    Incidents:Incident[];
}

const ModalEtatServiceCtrl = (props:ModalEtatServiceCtrlProps)=>{
    return (
        <div className={`modalEtatService ${props.className}`}>
            {props.Incidents.length === 0 &&
                <div className="not_incident"><span>Aucun incident en cours</span></div>
            }
            {props.Incidents.map(item=>(
                <div className="block_incident" key={`I${item.id}`}>
                    <div className="head_incident">
                        <div className="date">{format(new Date(item.incidentAt), "dd/MM/yyyy HH:mm")}</div>
                        <div className="app">{item.app}</div>
                        <div className="titre">{item.titre}</div>
                    </div>
                    <div className="body_incident">
                        <div className="description">{item.description}</div>
                        {item.incidentComments.length>0 &&
                            <div className="wrap_comments">
                                {item.incidentComments.map(comment=>(
                                    <div className="one_comment" key={`CI${comment.id}`}>
                                        <div className="date_comment">{format(new Date(comment.commentAt), "dd/MM/yyyy HH:mm")}</div>
                                        <div className="content_comment">{comment.comment}</div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}

const ModalEtatService = styled(ModalEtatServiceCtrl)`
    margin-top: 30px;
    .block_incident{
        border:solid 1px #ccc;
        border-radius: 4px;
        .head_incident{
            display: flex;
            justify-content: flex-start;
            border-bottom: solid 1px #ccc;
            .date, .app, .titre{
                padding: 0.25rem 0.5rem;
                border-right: solid 1px #ccc;
            }
        }
        .body_incident{
            padding: 1rem;
            .description{
                font-weight: bold;
                margin-bottom: 10px;
            }
            .wrap_comments{
                border-left: solid 6px #ccc;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                padding: 0 0.5rem;
                gap: 6px;
                .date_comment{
                    font-size: 13px;
                    font-weight: bold;
                    margin-bottom: 6px;
                }
            }
        }
    }
    .not_incident{
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-style: italic;
    }
`

export default ModalEtatService
