import styled, {keyframes} from "styled-components";
import { useRef, useState, useEffect } from "react";
import {ImSpinner6} from "react-icons/im";

interface InputTextCtrlProps {
    className?: string;
    id: string;
    isPending: boolean;
    current: string;
    Error: string | null;
    setValue: (x: string) => void;
    label: string;
    type:"text"|"date"
    isMaxContent?:boolean;
    isAlws?:boolean;
    isFormStd?:boolean;
    disabled?:boolean;
    isDirectChange?:boolean;

}

function isValidDate(dateString: string): boolean {
    const date = new Date(dateString);
    return !isNaN(date.getTime()) || dateString === "";
}

const InputTextCtrl = (props: InputTextCtrlProps) => {
    const [myText, setMyText] = useState<string>(props.current);
    const refInput = useRef<HTMLInputElement>(null);
    const {current} = props
    // Synchronisation de myText avec props.current
    useEffect(() => {
        setMyText(current);
    }, [current]);

    const handleChange = () => {
        if (refInput.current) {
            const myNewVal = refInput.current.value
            setMyText(myNewVal);
            if(props.type === "date" && isValidDate(myText) && props.isDirectChange){
                props.setValue(myText);
            }

        }
    };

    const validateOnBlur = () => {
        if(props.type === "date" && isValidDate(myText)){
            props.setValue(myText);
        } else {
            props.setValue(myText);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            validateOnBlur();
        }
    };

    return (
        <div className={`inputText ${props.className}`}>
            <label htmlFor={props.id}>{props.label}</label>
            <div className="wrap_input_in">
                <input
                    type={props.type}
                    disabled={props.disabled}
                    id={props.id}
                    className={`input_text ${props.Error ? "on_error" : ""} ${myText === "" ? "is_empty" : ""} ${props.isAlws ? "is_always" : ""}`}
                    ref={refInput}
                    value={myText}
                    onChange={handleChange}
                    onBlur={validateOnBlur}
                    onKeyDown={handleKeyPress}
                />
                <div className="wrap_save_input">
                    {props.isPending && <div className="saving_wrap">
                        <ImSpinner6 />
                    </div>}
                </div>
            </div>
            {props.Error && <div className="error_form">{props.Error}</div>}
        </div>
    );
};

const RotateSave = keyframes`
    from{
        transform: rotate(0);
    } to{
              transform: rotate(360deg);
      }
`

const InputText = styled(InputTextCtrl)`
    .wrap_save_input{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 35px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .wrap_input_in{
        padding-right: ${props => props.isPending ?  "30px" : "0"};
        position: relative;
    }
    .error_form{
        font-size: 13px;
        color: red;
    }
    .saving_wrap{
        width: 20px;
        margin: auto;
        height: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        animation: ${RotateSave} 0.7s linear infinite;
        color: ${props=>props.theme.Neutre};
        svg{
            margin: auto;
        }
    }
    label{
        font-weight: bold;
        color:${props=>props.theme.Primary};
        display: block;
        font-size: 14px;
    }
    input{
        border:none;
        border-radius: 4px;
        padding: 0.25rem 0.5rem 0.25rem 0;
        transition: padding-left 0.3s;
        font-size: 18px;
        height: ${props=>props.isFormStd ? "36px" : "auto"};
        width: ${props=>props.isMaxContent ? "max-content" : "100%"};
    &:hover, &:focus, &.is_empty, &.is_always{
        padding: 0.25rem 0.5rem;
        transition: padding-left 0.3s;
        background: ${props=>props.theme.colorBackInput};
        cursor: ${props=>props.isFormStd ? "normal"  : "pointer"};
    }
    &.color_force{
        &:hover, &:focus{
            background: ${props=>props.theme.colorBackInput};
        }
        &.empty{
            background: ${props=>props.theme.colorBackInput};
        }
    }
    &.empty{
        background: ${props=>props.theme.colorBackInput};
    }
    &.on_error{
        padding-left: 5px;
        background: ${props=>props.theme.WarningMegaLight};
    }
    }
`;

export default InputText;
