import React, {createContext, lazy, Suspense, useMemo, useState} from "react";
import styled from "styled-components";
import {useModal} from "../../../../hooks/useModal";
import NewsHeader from "./components/NewsHeader";
import TimedButton from "../../../../components/atoms/TimedButton/TimedButton";
import {TiPlus} from "react-icons/ti";
import FormNouvelleEditNews from "./components/FormNouvelleEditNews";
import TimedModalCanal from "../../../../components/molecules/TimedModal/TimedModalCanal";

const ListNews = lazy(()=>import("./components/ListNews"))

interface NewsCtrlProps{
    className?:string;
    supervisor?:boolean;
}


const TabChipsNews:Choice[] = [
    {id:0, libelle:"Active"},
    {id:1, libelle:"Inactive"},
]

const TabChipsChrono:Choice[] = [
    {id:2, libelle:"En cours"},
    {id:3, libelle:"Programmées"},
    {id:4, libelle:"Terminées"}
]
export interface ContextNewsProps{
    NewsCurrent:News|null;
    setCurr:(n:News)=>void;
}
export const ContextNews = createContext<ContextNewsProps>({NewsCurrent:null, setCurr:(n:News)=>{return;}})

const NewsCtrl=({className, supervisor}:NewsCtrlProps)=>{
    const {open, toggle} = useModal();
    const [newsCurrent, setNewsCurrent] = useState<News|null>(null)
    const setMyNews = (i:News)=>{
        setNewsCurrent(i);
    }
    return (
        <div className={`PageNews ${className}`}>
            <ContextNews.Provider value={{NewsCurrent:newsCurrent, setCurr:setMyNews}}>
                <NewsHeader titre={"Gestion des news"}>
                    <TimedButton onClick={toggle} themeColor={"Primary"} size={"sm"}>
                        <div className={`wrap_in_btn`}><TiPlus /><span>Créer une news</span></div>
                    </TimedButton>
                </NewsHeader>
                <div className="body_news">
                    <div className="side_list">
                        <Suspense fallback={"..."}>
                            <ListNews/>
                        </Suspense>
                    </div>
                    <div className="content_edit">
                        {newsCurrent ?
                           <FormNouvelleEditNews closeMe={()=>{}} News={newsCurrent}/>:
                           <div className="no_selection">Merci de choisir une news pour l'éditer</div>
                        }
                    </div>
                </div>
            </ContextNews.Provider>
            <TimedModalCanal closeMe={toggle} open={open}>
                <FormNouvelleEditNews closeMe={toggle}/>
            </TimedModalCanal>
        </div>
    )
}

const News = styled(NewsCtrl)`
    padding: 1% 2%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .no_selection{
        font-style: italic;
        font-size:18px;
        text-align: center;
        padding: 3rem;
    }
    h5{
        font-size: 16px;
        margin-bottom: 8px;
    }
    .body_news{
        flex-grow: 1;
        padding: 1rem 5rem;
        background: white;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        .side_list{
            flex-grow: 1;
            border-right: solid ${props => props.theme.Primary} 1px;
            height: 100%;
            overflow-y: auto;
            padding-right: 16px;
        }
        .content_edit{
            width: 840px;
            flex-shrink: 0;
            padding: 0 2rem;
            overflow-y: auto;
        }
    }
`

export default News;
