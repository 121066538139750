import {TabJour, TabJourISO, TabMois} from "../../services/constantes/constantes";
import {format} from "date-fns";

function ToCurrency(number:number){
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number);
}

function padTo2Digits(num:number) {
    return num.toString().padStart(2, '0');
}

function ConvertGoodDate(dat:string){
    return new Date(dat.slice(0,10)+"T12:00:00")
}

function DateObjetToEnFormat(date:Date){
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-'));
}

function formatDate(date:Date) {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join(':')
    );
}
function getDateFrByStringSimple(dat:string){
    const Tab = dat.split("-");
    return `${Tab[2]}/${Tab[1]}/${Tab[0]}`;
}
function getDateFrByStringNoH(dat:string){
    if(!dat){
        return '';
    }
    const TabDat = dat.split('T');
    const DatA = TabDat[0];
    const TabDatB = DatA.split('-');
    return `${TabDatB[2]}/${TabDatB[1]}/${TabDatB[0]}`;
}
function getDateFrByString(dat:string){
    const TabDat = dat.split('T');
    const DatA = TabDat[0];
    const TimeA = TabDat[1];
    const TabTime = TimeA.split('+');
    const TabDatB = DatA.split('-');
    return `${TabDatB[2]}/${TabDatB[1]}/${TabDatB[0]} à ${TabTime[0]}`;
}

function getYesterdayByDate(date:Date){
    const offset = 86400000;
    return new Date(date.getTime() - offset);
}
function getTomorrowByDate(date:Date){
    const offset = 86400000;
    return new Date(date.getTime() + offset);
}

function getWeekByDate(date:Date){
    date.setHours(12,0,0);
    const dayWeek = date.getDay() === 0 ? 7 : date.getDay();
    let Monday = new Date(date.getTime()-(86400000*(dayWeek-1)));
    let Sunday = new Date(date.getTime()+(86400000*(7-dayWeek)));
    Monday = new Date(Monday.setHours(12,0))
    Sunday = new Date(Sunday.setHours(12,0))
    return {Monday, Sunday}
}
function getAllDateWeekByDate(date:Date){
    const {Monday, Sunday} = getWeekByDate(date);
    let dateRotate = new Date(Monday.getTime());
    dateRotate.setHours(12,0,0);
    const mySunday = new Date(Sunday.getTime());
    mySunday.setHours(12,0,0)
    const TabDates:Date[] = [];
    while(dateRotate<=mySunday){
        TabDates.push(new Date(dateRotate.getTime()))
        dateRotate = new Date(dateRotate.getTime() + 86400_000)
    }
    return TabDates;
}

function getWeeksFourFour(){
    let Today = new Date();
    Today.setHours(0,0,0);
    const Today4Week = new Date(Today.getTime() - (7 * 86400000))
    let idx = 0;
    let tabReturn:{Monday:Date, Sunday:Date}[] = []
    let current = Today4Week
    while (idx<=7){
        tabReturn.push(getWeekByDate(current));
        current = new Date(current.getTime() + (7*86400000))
        idx++;
    }
    return tabReturn;
}

function getDate8Weeks(){
    const Today = new Date();
    const Today4Week = new Date(Today.getTime() - (7 * 86400000))
    const {Monday} = getWeekByDate(Today4Week);
    const Date8Week = new Date(Today4Week.getTime() + (56*86400000))
    const {Sunday} = getWeekByDate(Date8Week);
    return {Monday, Sunday}
}

// renvoi premier et dernier jour du mois
function getFirstLastDay(year:number, month:number){
    return {first:new Date(year, month, 1), last:new Date(year, month+1, 0)}
}

function getWeeksForHeuresSupp(date:Date){
    const {FirstMonth, LastMonth} = getMonthByDate(date)
    const {Monday:MondayFirst} = getWeekByDate(FirstMonth);
    const {Monday:MondayLast} = getWeekByDate(LastMonth);
    let myCurrent = MondayFirst
    let myWeeks = []
    while (myCurrent <= MondayLast){
        const {Monday, Sunday} = getWeekByDate(myCurrent)
        if(Sunday.getMonth() === date.getMonth()) {
            myWeeks.push({Monday: Monday, Sunday: Sunday})
        }
        myCurrent = new Date(myCurrent.getTime() + 7*86400000)
    }
    return myWeeks
}

// renvoi les semaines en prenant le 1er du mois
function getWeeksByDate(date:Date){
    const {FirstMonth, LastMonth} = getMonthByDate(date)
    const {Monday:MondayFirst} = getWeekByDate(FirstMonth);
    const {Monday:MondayLast} = getWeekByDate(LastMonth);
    let myCurrent = MondayFirst
    let myWeeks = []
    while (myCurrent <= MondayLast){
        const {Monday, Sunday} = getWeekByDate(myCurrent)
        if(Sunday.getMonth() === myCurrent.getMonth()) {
            myWeeks.push({Monday: Monday, Sunday: Sunday})
        }
        myCurrent = new Date(myCurrent.getTime() + 7*86400000)
    }
    return myWeeks
}

function getWeekByTwoDate(date1:Date, date2:Date){
    const {Monday:MondayFirst, Sunday:SundayFirst} = getWeekByDate(date1);
    const {Monday:MondayLast, Sunday:SundayLast} = getWeekByDate(date2);
    let myCurrent = MondayFirst
    let myWeeks = []
    while (myCurrent < MondayLast){
        const {Monday, Sunday} = getWeekByDate(myCurrent)
        if(Sunday.getMonth() === myCurrent.getMonth()) {
            myWeeks.push({Monday: Monday, Sunday: Sunday})
        }
        myCurrent = new Date(myCurrent.getTime() + 7*86400000)
    }
    return myWeeks
}
function getWeekByTwoDateS(date1:Date, date2:Date){
    const {Monday:MondayFirst, Sunday:SundayFirst} = getWeekByDate(date1);
    const {Monday:MondayLast, Sunday:SundayLast} = getWeekByDate(date2);
    let myCurrent = MondayFirst
    let myWeeks = []
    while (myCurrent < MondayLast){
        const {Monday, Sunday} = getWeekByDate(myCurrent)
        if(Sunday.getMonth() === date1.getMonth()) {
            myWeeks.push({Monday: Monday, Sunday: Sunday})
        }
        myCurrent = new Date(myCurrent.getTime() + 7*86400000)
    }
    return myWeeks
}
function getMonthsByTwoDate(date1:Date, date2:Date){

    date1.setHours(12,0,0)
    date2.setHours(12,0,0)
    const {FirstMonth, LastMonth} = getMonthByDate(date1);
    const {FirstMonth:first2, LastMonth:last2} = getMonthByDate(date2);

    first2.setHours(12,0,0);
    let myCurrent = FirstMonth;
    let myMonths = [];
    while(myCurrent <first2 || myCurrent.toISOString().slice(0,10) === first2.toISOString().slice(0,10)){
        const {FirstMonth:F, LastMonth:L} = getMonthByDate(myCurrent)
        myMonths.push({start:F, end:L})
        myCurrent = new Date(myCurrent.getTime() + 32*86400000)
        myCurrent = new Date(myCurrent.getFullYear(), myCurrent.getMonth(), 1);
    }
    return myMonths;
}

function getLastWeekByDate(date:Date){
    const {Monday} = getWeekByDate(date);
    let prevMonday = new Date(Monday.getTime()-((7)*(86400000)));
    let prevSunday = new Date(prevMonday.getTime()+(86400000*6));
    prevMonday = new Date(prevMonday.setHours(12,0))
    prevSunday = new Date(prevSunday.setHours(12,0))
    return {prevMonday, prevSunday}
}
function getNextWeekByDate(date:Date){
    const {Monday} = getWeekByDate(date);
    const NextMonday = new Date(Monday.getTime()+((7)*(86400000)));
    const NextSunday = new Date(NextMonday.getTime()+(86400000*6));
    return {NextMonday, NextSunday}
}

function getMonthByDate(date:Date){
    const myDateHere = new Date(new Date(date.getTime()).setHours(12,0,0))
    const myDateFirst = new Date(new Date(myDateHere.getTime()).setDate(1))
    const myNextMonth = new Date(new Date(myDateFirst.getTime()).setMonth(myDateFirst.getMonth()+1))
    const myLastDay = new Date(new Date(myNextMonth.getTime() - 86400000).setHours(12,0,0))

    return {FirstMonth:myDateFirst, LastMonth:myLastDay};
}
function getLastMonthByDate(date:Date){
    const myDateHere = new Date(new Date(date.getTime()).setHours(12,0,0))
    const myDateFirst = new Date(new Date(myDateHere.getTime()).setDate(1))
    const myDateLastFirst = new Date(new Date(myDateFirst.getTime()).setMonth(myDateFirst.getMonth()-1))
    const myNextMonth = new Date(new Date(myDateLastFirst.getTime()).setMonth(myDateLastFirst.getMonth()+1))
    const myLastDay = new Date(new Date(myNextMonth.getTime() - 86400000).setHours(12,0,0))

    return {FirstPrevMonth:myDateLastFirst, LastPrevMonth:myLastDay};
}
function getNextMonthByDate(date:Date){
    const myDateHere = new Date(new Date(date.getTime()).setHours(12,0,0))
    const myDateFirst = new Date(new Date(myDateHere.getTime()).setDate(1))
    const myDateLastFirst = new Date(new Date(myDateFirst.getTime()).setMonth(myDateFirst.getMonth()+1))
    const myNextMonth = new Date(new Date(myDateLastFirst.getTime()).setMonth(myDateLastFirst.getMonth()+1))
    const myLastDay = new Date(new Date(myNextMonth.getTime() - 86400000).setHours(12,0,0))
    return {FirstNextMonth:myDateLastFirst, LastNextMonth:myLastDay};
}

function getThisYearByDate(Today:Date){

    const year = Today.getFullYear();
    const FirstDay = new Date(year, 0, 1);
    const LastDay = new Date(year, 11, 31);
    return {FirstDay, LastDay};
}

function DateToDateStrFR(dat:Date){
    return `${(`0${dat.getDate()}`).slice(-2)}/${(`0${dat.getMonth()+1}`).slice(-2)}/${dat.getFullYear()}`;
}

function DateStrFrToDateStrEng(dateStr:string){
    const Tab = dateStr.split('/');
    const day = Tab[0];
    const month = Tab[1];
    const year = Tab[2];
    return `${year}-${month}-${day}`;
}
function DateToDateStrEng(Dat:Date){
    const month = (`0${Dat.getMonth()+1}`).slice(-2);
    const day = (`0${Dat.getDate()}`).slice(-2);
    return `${Dat.getFullYear()}-${month}-${day}`;
}
function HeuresToDeci(hor:string){
    const Tab = hor.split(":")
    if(Tab.length<2 ||  Tab[1].length<2){
        return 0;
    }
    const sec = Tab.length>2 ? parseInt(Tab[2])/3600 : 0
    return parseInt(Tab[0])+(parseInt(Tab[1])/60)+sec
}

function DeciToHeures(deci:number, separator:string=":"){
    const Rd = Math.floor(deci);
    const Reste = deci - Rd;
    const Min = Reste*60;
    const MinRd = Math.round(Min);
    const HeuresStr = Rd<10 ? `0${Rd}` : Rd;
    const MinStr = MinRd<10 ? `0${MinRd}` : MinRd;
    return `${HeuresStr}${separator}${MinStr}`;
}
function ConvertMinToHeure(base:number){
    const HeureExact = base/60;
    const minReste = HeureExact - Math.floor(HeureExact);
    const Minute = Math.round(minReste*60);
    const HeureStr = Math.floor(HeureExact) < 10 ? '0'+Math.floor(HeureExact) : Math.floor(HeureExact);
    const MinuteStr = Minute <10 ? '0'+Minute : Minute;
    return `${HeureStr}:${MinuteStr}`;
}
function ConvertBase5(base:number){
    let HeureExact = base/12;
    const Reste = (HeureExact - Math.floor(base/12));
    const Min = Reste*60;
    if(HeureExact >=24) HeureExact = HeureExact - 24;
    return `${Math.floor(HeureExact)<10 ? `0${Math.floor(HeureExact)}` : Math.floor(HeureExact)}:${Math.round(Min)<10 ? `0${Math.round(Min)}` : Math.round(Min)}`;
}
function ConvertToBase5(heure:string){
    const Tab = heure.split(':');
    if(Tab.length!==2){
        return 0;
    } else {
        return parseInt(Tab[0])*12+Math.floor(parseInt(Tab[1])/5);
    }
}
function DateToYMD(date:Date){
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

function getNumWeek(date:Date){
    // const startDate = new Date(date.getFullYear(), 0, 1);
    // const days = Math.floor((date.getTime() - startDate.getTime()) /
    //     (24 * 60 * 60 * 1000));
    //
    // if(date.getFullYear() === 2024){
    //     if (Math.ceil(days / 7) === 53){
    //         return 1
    //     } else {
    //         return Math.ceil(days / 7) +1;
    //     }
    // }
    //
    // return Math.ceil(days / 7) === 0 ? 53 : Math.ceil(days / 7);
    // Copie de la date pour ne pas modifier l'original
    let tempDate = new Date(date.valueOf());

    // Trouver le jeudi de la semaine courante
    let dayNumber = (tempDate.getDay() + 6) % 7;
    tempDate.setDate(tempDate.getDate() - dayNumber + 3);

    // Trouver le premier jeudi de l'année
    let firstThursday = new Date(tempDate.getFullYear(), 0, 1, 12, 0);
    if (firstThursday.getDay() !== 4) {
        firstThursday.setMonth(0, 1 + ((4 - firstThursday.getDay()) + 7) % 7);
    }

    // Calculer le numéro de la semaine
    let weekNumber = 0 + Math.ceil((tempDate.getTime() - firstThursday.getTime()) / (7 * 24 * 60 * 60 * 1000));

    return weekNumber;
}
function getISOWeekNumber(date:Date) {
    // Copie de la date pour ne pas modifier l'original
    let tempDate = new Date(date.valueOf());

    // Trouver le jeudi de la semaine courante
    let dayNumber = (tempDate.getDay() + 6) % 7;
    tempDate.setDate(tempDate.getDate() - dayNumber + 3);

    // Trouver le premier jeudi de l'année
    let firstThursday = new Date(tempDate.getFullYear(), 0, 1);
    if (firstThursday.getDay() !== 4) {
        firstThursday.setMonth(0, 1 + ((4 - firstThursday.getDay()) + 7) % 7);
    }

    // Calculer le numéro de la semaine
    let weekNumber = 0 + Math.ceil((tempDate.getTime() - firstThursday.getTime()) / (7 * 24 * 60 * 60 * 1000));

    return weekNumber;
}

function getWeeksYear(year:number){
    const FourDay = new Date(year, 0, 4);
    const numDay = FourDay.getDay() === 0 ? 6 : FourDay.getDay() - 1;
    const firstMonday =  new Date(new Date(FourDay.getTime() - 86400*1000*numDay).setHours(18,0));
    let tabWeeks = [];
    let numSem = 1;
    let daty = firstMonday;
    while(daty.getFullYear() === year){
        tabWeeks.push({
            numSem:numSem,
            monday:daty,
            sunday:new Date(new Date(daty.getTime() + 86400*1000*6))
        })
        daty = new Date(new Date(daty.getTime() + 86400*1000*7));
        numSem++;
    }
    return tabWeeks;
}
export interface OneWeekEleven{
    numSem:number;
    monday:Date;
    sunday:Date;
}
function getElevenWeeks(today:Date){

    const numDay = today.getDay() === 0 ? 6 : today.getDay() - 1;
    const firstMonday =  new Date(new Date(today.getTime() - 86400*1000*numDay).setHours(18,0));
    let idx=0;
    let tabWeeks:OneWeekEleven[] = [];
    let daty = firstMonday;
    while(idx<18){
        tabWeeks.push({
            numSem:getNumWeek(daty),
            monday:daty,
            sunday:new Date(new Date(daty.getTime() + 86400*1000*6))
        })
        daty = new Date(new Date(daty.getTime() + 86400*1000*7));
        idx++;
    }
    return tabWeeks;
}
export interface OneMonthEleven{
    mois: { num:number, numMois:string, name:string }|undefined;
    firstDay:Date;
    lastDay:Date;
}
function getElevenMonths(today:Date){
    const FirstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    let MonthNext= today.getMonth()+1;
    let YearNext = today.getFullYear();
    if(MonthNext>12){
        MonthNext = 1;
        YearNext++;
    }
    const NextDate = new Date(YearNext, MonthNext, 1);
    let tabMonthy:OneMonthEleven[] = [];
    let daty = FirstDay;
    let idx = 0;
    tabMonthy.push({
        mois:TabMois.find(item=>item.num === daty.getMonth()+1),
        firstDay:daty,
        lastDay:new Date(NextDate.getTime() - 86400*1000)
    })
    while(idx<6){
        let newMonth = daty.getMonth()+1+idx;
        let newYear = daty.getFullYear();
        let newMonthN = newMonth+1;
        let newYearN = newYear;
        if(newMonth>12){
            newMonth = newMonth - 12;
            newYear++;
        }
        if(newMonthN>12){
            newMonthN= newMonthN - 12;
            newYearN++;
        }
        const Newdaty = new Date(newYear, newMonth, 1);
        let datyN =  new Date(newYearN, newMonthN, 1);
        datyN = new Date(datyN.getTime() - 86400*1000);
        tabMonthy.push({
            mois:TabMois.find(item=>item.num === Newdaty.getMonth()+1),
            firstDay:Newdaty,
            lastDay:datyN
        })

        idx++;
    }
    return tabMonthy;
}
function getFirstMondayByDate(Dat:Date){
    const numDay = Dat.getDay() === 0 ? 6 : Dat.getDay() - 1;
    return  new Date(new Date(Dat.getTime() - 86400*1000*numDay).setHours(18,0));
}
export interface OneNumRotation{
    start:Date,
    end:Date,
    numRotation:number;
}

function GetSequenceRotation(us:UserSequence[], User:User, dateSearch:Date){
    if(!User.startRotationAt){
        return null;
    } else {
        const sequences = us.sort((a:UserSequence, b:UserSequence)=>a.ordre > b.ordre ? 1 : -1)
        const dateStartRotation = new Date(User.startRotationAt)
        dateStartRotation.setHours(12,0,0);
        const myRealSearch = dateSearch;
        myRealSearch.setHours(12,0,0);
        const numDay = dateStartRotation.getDay() === 0 ? 6 : dateStartRotation.getDay() === 1 ? 0 : dateStartRotation.getDay() - 1;
        const numDay2 =myRealSearch.getDay() === 0 ? 6 : myRealSearch.getDay() === 1 ? 0 : myRealSearch.getDay() - 1;
        const firstMonday2 = new Date(new Date(myRealSearch.getTime() - 86400*1000*numDay2).setHours(12,0));
        const firstMonday =  new Date(new Date(dateStartRotation.getTime() - 86400*1000*numDay).setHours(12,0));
        const numSemBeet = Math.round(((firstMonday2.getTime() - firstMonday.getTime())/86400000) / 7);
        let index =(numSemBeet % sequences.length);
        return sequences[index];
    }
}

function GetCentreSemaineType(user:User, dateTo:Date, sequences:UserSequence[]){
    if(!user.startRotationAt){
        return null;
    }
    const dateApplic = new Date(new Date(dateTo.getTime()).setHours(12,0,0))
    const StartRotation = new Date(new Date(user.startRotationAt?.slice(0,10)).setHours(12,0,0))
    const {Monday:MStart} = getWeekByDate(StartRotation);
    const {Monday:MApplic} = getWeekByDate(dateApplic);
    const MondayStartOK = new Date(new Date(MStart.getTime()).setHours(12,0,0))
    const MondayApplicOK = new Date(new Date(MApplic.getTime()).setHours(12,0,0))
    const numSemBeet = Math.round(((MondayApplicOK.getTime() - MondayStartOK.getTime())/86400000) / 7);
    let index =(numSemBeet % sequences.length);
    const mySeqOrdre = [...sequences].sort((a, b)=>a.ordre > b.ordre ? 1 : -1);
    const MySeq = mySeqOrdre[index];
    if(!MySeq || !MySeq.CentreSemaineType){
        return null;
    }
    return MySeq.CentreSemaineType;
}

function GetNumRotation(startRotation:Date, dateStartX:Date, dateStopX:Date, sequence:number[], nbRotation:number = 1, wm=false){
    console.log(sequence);
    const dateStop = new Date(dateStopX.setHours(18,0));
    let dateStart =  new Date(dateStartX.setHours(18,0));
    startRotation.setHours(18,0,0);
    const Today = new Date((new Date()).setHours(18,0));
    if(!wm){
        if(dateStart<Today){
            dateStart = Today;
        }
    }

    const numDay = startRotation.getDay() === 0 ? 6 : startRotation.getDay() === 1 ? 0 : startRotation.getDay() - 1;
    const numDay2 =dateStart.getDay() === 0 ? 6 : dateStart.getDay() === 1 ? 0 : dateStart.getDay() - 1;
    const firstMonday2 = new Date(new Date(dateStart.getTime() - 86400*1000*numDay2).setHours(18,0));
    const firstMonday =  new Date(new Date(startRotation.getTime() - 86400*1000*numDay).setHours(18,0));
    firstMonday.setHours(18,0)
    const numSemBeet = Math.round(((firstMonday2.getTime() - firstMonday.getTime())/86400000) / 7);
    let index =(numSemBeet % nbRotation);


    let mondayStart = getFirstMondayByDate(dateStart);
    let tabRotation:OneNumRotation[] = [];

    while(mondayStart<=dateStop){
        const startDate = mondayStart<dateStart ? dateStart : mondayStart;
        let endDate = new Date(mondayStart.getTime()+(86400000*6));
        if(endDate>dateStop){
            endDate = dateStop;
        }
        tabRotation.push({
            start:startDate,
            end:endDate,
            numRotation:sequence[index]
        })
        mondayStart = new Date(mondayStart.getTime()+(86400000*7));
        index++;
        if(index>sequence.length-1){
            index = 0;
        }
    }

    return tabRotation
}

function GetNumJourFr(Date:Date){
    let eltJour = null;
    const DayDate = Date.getDay() === 0 ? 6 : Date.getDay() - 1
    if(TabJour.find(item=>item.num === DayDate) !== undefined){
        eltJour = TabJour.find(item=>item.num === DayDate);
    }
    return eltJour ? eltJour.name : 'nc';
}

function GetWeeksNum(From:Date|undefined){
    const MyDaty = !!From ? From : new Date();
    let myNumWeeks:OneWeekEleven[] = [];
    MyDaty.setHours(0,0);
    const firstDatMonthX = new Date(MyDaty.getFullYear(), MyDaty.getMonth(), 1 );
    const DayWeekFDM = firstDatMonthX.getDay()=== 0 ?  6 : firstDatMonthX.getDay()-1;
    let Next =  new Date(new Date(firstDatMonthX.getTime() - 86400*1000*DayWeekFDM).setHours(18,0));
    const NextNext = new Date(Next.getTime()+(86400*1000*7));
    const MonthCompare = MyDaty.getMonth();
    let monthNext = (Next.getMonth() === 11 && NextNext.getMonth() === 0) ?  0 : Next.getMonth();
    while(monthNext <= MonthCompare && Next.getFullYear() <= MyDaty.getFullYear()){
        myNumWeeks.push({
            numSem:getNumWeek(Next),
            monday:Next,
            sunday:new Date(Next.getTime()+(86400*1000*6))
        })
        Next = new Date(Next.getTime()+(86400*1000*7));
        monthNext = Next.getMonth();
    }
    return myNumWeeks;
}

function DecToHHMM(dec:number){
    const Heure = Math.floor(dec);
    const MinDec = dec - Heure;
    const Minutes = Math.round(MinDec*60);
    return `${((`0${Heure}`).slice(-2))}:${((`0${Minutes}`).slice(-2))}:00`
}
function MinutesToHHMM(minutes:number, secs=true){
    const dec = minutes/60;
    const Heure = Math.floor(dec);
    const MinDec = dec - Heure;
    const Minutes = Math.round(MinDec*60);
    const Add = secs ? ":00" : "";
    return `${((`0${Heure}`).slice(-2))}:${((`0${Minutes}`).slice(-2))}${Add}`
}

function RoundMe(num:number){
    return Math.round(num*100)/100
}

function getDayStrByDate(dat:Date, isShort=false){
    const numDay = dat.getDay();


    const myD = TabJourISO.find(j=>j.num === numDay);
    if(myD){
        return isShort ? myD.short : myD.name;
    } else {
        return '--'
    }
}

const getDateWeekByDate = (d:Date)=>{
    const dNoon = new Date(d.setHours(12,0,0))
    const myNumDay = dNoon.getDay()===0 ? 7 : dNoon.getDay();
    const Monday = new Date(dNoon.getTime() - (myNumDay-1)*86400000)
    const Sunday = new Date(Monday.getTime() + 6*86400000)
    return {Monday, Sunday}
}
const getNumWeekByDate = (d:Date)=>{
    const dNoon = new Date(d.setHours(12,0,0))
    const tempDate = new Date(dNoon.valueOf());

    // Trouver le jeudi de la semaine courante
    const dayNumber = (tempDate.getDay() + 6) % 7;
    tempDate.setDate(tempDate.getDate() - dayNumber + 3);

    // Trouver le premier jeudi de l'année
    const firstThursday = new Date(tempDate.getFullYear(), 0, 1);
    if (firstThursday.getDay() !== 4) {
        firstThursday.setMonth(0, 1 + ((4 - firstThursday.getDay()) + 7) % 7);
    }

    // Calculer le numéro de la semaine
    return Math.ceil((tempDate.getTime() - firstThursday.getTime()) / (7 * 24 * 60 * 60 * 1000));
}

const generateWeekDates = (monday: Date, sunday: Date): Date[] => {
    monday.setHours(12,0,0)
    sunday.setHours(12,0,0)
    const dates: Date[] = [];
    let current = new Date(monday);
    while (current <= sunday || (format(current, "dd/MM") === format(sunday, "dd/MM"))) {
        dates.push(new Date(current));
        current = new Date(current.getTime() + 86400000); // Ajoute un jour
    }
    return dates;
};

export {
    generateWeekDates,
    getNumWeekByDate,
    getDateWeekByDate,
    GetCentreSemaineType,
    getAllDateWeekByDate,
    HeuresToDeci,
    getDayStrByDate,
    RoundMe,
    GetNumJourFr,
    GetWeeksNum,
    DateToDateStrEng,
    GetNumRotation,
    getElevenMonths,
    getElevenWeeks,
    getWeekByDate,
    getLastWeekByDate,
    getMonthByDate,
    getYesterdayByDate,
    getLastMonthByDate,
    DateStrFrToDateStrEng,
    getDateFrByString,
    formatDate,
    getDateFrByStringSimple,
    getDateFrByStringNoH,
    DeciToHeures,
    ConvertBase5,
    ConvertToBase5,
    getNumWeek,
    getWeeksYear,
    getTomorrowByDate,
    getNextWeekByDate,
    getNextMonthByDate,
    DateToDateStrFR,
    getThisYearByDate,
    getWeeksByDate,
    DateToYMD,
    getFirstLastDay,
    DecToHHMM,
    MinutesToHHMM,
    DateObjetToEnFormat,
    getWeeksFourFour,
    getDate8Weeks,
    ConvertMinToHeure,
    getWeekByTwoDate,
    getWeeksForHeuresSupp,
    ConvertGoodDate,
    getMonthsByTwoDate,
    getWeekByTwoDateS,
    GetSequenceRotation,
    getISOWeekNumber,
    ToCurrency
}
