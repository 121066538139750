import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {BsCardImage} from "react-icons/bs";

interface TimedDropZoneImageCtrlProps{
    className?:string;
    width?:string;
    height?:string;
    setImage:(file:File|null)=>void;
    imageBase?:string;
}

const TimedDropZoneImageCtrl = ({className, setImage, imageBase}:TimedDropZoneImageCtrlProps)=>{
    const refDropZone = useRef<HTMLDivElement>(null)
    const refInputFile = useRef<HTMLInputElement>(null);
    const [previewUrl, setPreviewUrl] = useState(imageBase ? imageBase : "");
    const [dragging, setDragging] = useState(false);
    useEffect(() => {
        setPreviewUrl(imageBase||"")
    }, [imageBase]);
    const handleFile =(file:File|null) => {
        //you can carry out any file validations here...
        if(file) {
            setImage(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    }
    const handleDrag = (e:any)=>{
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    }
    const handleDragIn = (e:any)=>{
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    }
    const handleDragOut = (e:any)=>{
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    }
    const handleDrop = (e:any)=>{
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        let imageFile = e.dataTransfer.files[0];
        handleFile(imageFile);
    }
    useEffect(()=>{
        const DropZone = refDropZone.current;
        if(DropZone){
            DropZone.addEventListener('dragenter', handleDragIn);
            DropZone.addEventListener('dragleave', handleDragOut);
            DropZone.addEventListener('dragover', handleDrag);
            DropZone.addEventListener('drop', handleDrop)
        }
        return ()=>{
            if(DropZone){
                DropZone.removeEventListener('dragenter', handleDragIn);
                DropZone.removeEventListener('dragleave', handleDragOut);
                DropZone.removeEventListener('dragover', handleDrag);
                DropZone.removeEventListener('drop', handleDrop)
            }
        }
    }, [])
    const handleClick = ()=>{
        if(refInputFile.current){
            refInputFile.current.click();
        }
    }
    return (
        <div className={`TimedDropZoneImage ${className} ${dragging ? 'dragging' : ''}`} ref={refDropZone} onClick={handleClick}>
            {!dragging &&
                <div className={`beforeDrag`}>
                    <BsCardImage/>
                </div>
            }
            {dragging &&
                <div className={`duringDrag`}><p>Déposez votre image</p></div>
            }
            <input
                type="file"
                accept='image/*'
                ref={refInputFile} hidden
                onChange={e => handleFile(e.target.files ? e.target.files[0] : null)}
            />
            {previewUrl && !dragging && <div className={`afterDrag`}><img src={previewUrl} alt='image' /></div> }
        </div>
    )
}

export const TimedDropZoneImage = styled(TimedDropZoneImageCtrl)`
    width: ${props => props.width || "100%"};
    height: ${props => props.height || "100%"};
    background: ${props => props.theme.NeutreExtraLight};
    position: relative;
  border-radius: 8px;
  overflow: hidden;
    border:solid 1px ${props=>props.theme.Primary};
  &:hover{
    cursor: pointer;
  }
  .afterDrag{
    position: absolute;
    background: white;
    z-index: 3;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img{
      width: 100%;
      height: auto;
    }
  }
    .beforeDrag{
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      svg{
        font-size: 46px;
        margin: auto;
        path{
          fill:white;
          stroke: white;
        }
      }
    }
    .duringDrag{
      display: flex;
      z-index: 999;
      flex-direction: column;
      justify-content: center;
      //position: absolute;
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
      p{
        text-align: center;
        font-size: 18px;
        color:white;
      }
    }
    &.dragging{
      background: ${props => props.theme.NeutreLight};
      .beforeDrag{
        display: none;
      }
      
    }
`
