import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {useGetCentreActuel} from "../../features/centre/centre.hooks";
import {queryClient} from "../../main/App";
import {useGetUser} from "../../features/user/user.hooks";
import {createPortal} from "react-dom";
import {BiTask} from "react-icons/bi";
import {FaUser} from "react-icons/fa";

interface TimedNotificationTaskCtrlProps{
    className?:string;
}

interface NotifTask{
    id : number,
    type:string,
    idCreator:number,
    TaskData:{id:number, isDid:boolean, libTask:string, UserAffected: {id:number, fullName:string}}
}

const TimedNotificationTaskCtrl = (props:TimedNotificationTaskCtrlProps)=>{
    const CentreActuel = useGetCentreActuel();
    const UserQuery = useGetUser();
    const [myNotif, setMyNotif] = useState<NotifTask|null>(null);
    const [isVisible, setIsVisible] = useState(false)
    const mercureEventSourceRef = useRef<EventSource | null>(null);

    const updateTask = (data:NotifTask)=>{
        queryClient.invalidateQueries(["tasks"])
        if(UserQuery.data && UserQuery.data.id === data.idCreator) {
            console.log("ajout");
            setMyNotif(data);
            setIsVisible(true);
        } else {
            console.log("nope")
        }
    }
    useEffect(()=>{
        let myEventSource:EventSource|null;
        if(CentreActuel.data) {
            console.log("Ok je suis là")
            const url = new URL(`${process.env.REACT_APP_MERCURE_URL}`);
            url.searchParams.append("topic", `didTask/${CentreActuel.data.id}`);


            if (mercureEventSourceRef.current) {
                mercureEventSourceRef.current.close();
            }
            myEventSource = new EventSource(url);
            mercureEventSourceRef.current = myEventSource;

            myEventSource.onmessage = event => {
                console.log("✌️ task Event !!")
                const data = JSON.parse(event.data)
                console.log(data);
                updateTask(data);

            }
        }
        return ()=>{
            if (mercureEventSourceRef.current) {
                mercureEventSourceRef.current.close();
                mercureEventSourceRef.current = null;
            }
            if(myEventSource){
                myEventSource.close();
            }
        }
    }, [CentreActuel.data])
    useEffect(() => {
        if(isVisible){
            setTimeout(()=>{
                setIsVisible(false)
                setTimeout(()=>{
                    setMyNotif(null);
                }, 300)
            }, 2500)
        }
    }, [isVisible]);


    return createPortal(
        <div className={`timedNotificationTask ${props.className} ${isVisible ? "visible" : "leaving"}`}>
            {!!myNotif &&
                <>
                    <div className="head">{myNotif.type === "isDid"  ? (myNotif.TaskData.isDid ? "Tâche réalisée" : "Tâche annulée")  : "Tâche déplacée"}</div>
                    <div className="body">
                        <div className="titreTask">
                            <BiTask /><span>{myNotif.TaskData.libTask}</span>
                        </div>
                        <div className="affected">
                            <FaUser /><span>{myNotif.TaskData.UserAffected.fullName}</span>
                        </div>
                    </div>
            </>
            }
        </div>, document.body
    )
}

const TimedNotificationTask = styled(TimedNotificationTaskCtrl)`
    position: fixed;
    right: -270px;
    bottom: 10px;
    width: 260px;
    min-height: 80px;
    background: white;
    border-radius: 4px;
    box-shadow: 2px 2px 3px rgba(0,0,0,.2);
    transition: right 0.3s;
    z-index: 999;
    .head{
        background: ${props => props.theme.Primary};
        color: white;
        padding: 0.5rem;
    }
    .body{
        padding: 1rem;
    }
    .titreTask{
        margin-bottom: 6px;
    }
    .titreTask, .affected{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
    }
    &.visible{
        right: 10px;
        transition: right 0.3s;
    }
`

export default TimedNotificationTask
